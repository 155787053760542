<template>
  <!-- 实名认证用户隐私协议 -->
  <div class="container">
    <p style="text-align: center;">人脸识别服务协议</p>
    <p>欢迎您使用人脸识别功能，本服务协议是北京易服立达科技有限公司（“易服立达”）与用户（“您”）为明确服务内容而签订的有效协议。在接受本协议之前，请仔细阅读本协议的全部内容。</p>
    <p class="bold">一、功能说明</p>
    <p>为保障用户账户的安全，提供更好的服务，易服立达在提供部分产品及服务之前，采用人脸识别核身验证功能对用户的身份进行认证，用于验证操作人是否为账户持有者本人，通过人脸识别结果评估是否为用户提供产品或服务。该功能由百度云等机构提供核验数据及技术支持。</p>
    <p class="bold">二、授权与许可</p>
    <p>如您点击“确认”或以其他方式选择接受本协议规则，则视为您在使用人脸识别服务时，同意并授权易服立达获取、使用您在申请易服立达服务过程中所提供的个人信息（包括姓名、身份证号）及人脸影像数据，并提供给合法存有您信息的第三方合作机构进行比对核验，以核验您的身份。如您不同意本协议的任何内容，或者无法准确理解协议内容的解释，请不要进行后续操作。</p>
    <p class="bold">三、信息安全声明</p>
    <p>易服立达承诺对您的个人信息严格保密，并基于国家监管部门认可的加密算法进行数据加密传输，数据加密存储。如因不可抗力、计算机黑客袭击、系统故障、通讯故障、电脑病毒、恶意程序攻击及其他不可归因于易服立达的情况而导致用户损失的，易服立达不承担任何责任。易服立达与合作机构签订保密合同，并要求合作伙伴做好用户信息安全保障，承诺尽到信息安全保护义务。</p>
    <p class="bold">四、协议的效力及变更</p>
    <p>本协议在签署后立即生效，您在易服立达平台内点击了"确认"或以其他方式选择接受本协议规则，即视为对本协议的签订。在不损害用户利益的前提下，易服立达保留修改或增补本协议内容的权利，并以在官方网站等途径通知的方式予以公布，无需另行单独通知您。若您在本协议内容通知变更后继续办理相关业务的，表示您已充分阅读、理解并接受变更后的协议内容，也将遵循变更后的协议内容办理相关业务。若您不同意变更后的协议内容，您应向易服立达提出终止本协议并停止办理相关业务。</p>
    <p class="bold">五、纠纷的解决办法</p>
    <p>因履行本协议或与本协议有关的任何争议，由协议签订各方协商解决。协商不成的，协议各方均可向原告方住所地人民法院提起诉讼。</p>
  </div>
</template>

<script>
export default {
  name: "RealNameAuthentication",
  data() {
    return {
      
    };
  },
  created() {},
  methods: {
   
  },
};
</script>

<style scoped lang="scss">
.container {
  padding: 16px;
  .indent{
    text-indent: 1.5em;
  }
  p {
    font-size: 14px;
    line-height: 18px;
    color: #333;
    margin-bottom: 16px;
    text-align: justify;
  }
  .bold {
    font-weight: bold;
  }
  .underline{
    text-decoration: underline;
  }
}
 
</style>
